import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteReticles, savePublicReticle, resaveReticleInfo, changeStatusReticle, changeCalculationReticle, resaveAdminReticleInfo, changeAdminStatusReticle, publishReticle, changeUserStatusReticleInPublic, changeAdminStatusReticleInPublic } from '../../../../redux/reticles/reticles-operations';
import { changeCurrentReticle } from '../../../../redux/reticles/reticles-actions';
import { addTab, changeReticlesID, addSavingID, getTabs, selectActiveTab, setUpdateData } from '../../../../redux/reticles/tabs-reducer';
import { addZoomData, setCurrentZoomData } from '../../../../redux/zoom/zoom-reducer';
import { addColorThemeData } from '../../../../redux/colorTheme/colorTheme-reducer';
import { addDeviceData } from '../../../../redux/devices/device-reducer';
import { getUserEmail, getUserRole } from '../../../../redux/auth/auth-selectors';
import { addGridData } from '../../../../redux/grid/grid-reducer';
import { ReactComponent as SaveIcon } from '../../../img/saveIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../img/deleteIcon.svg';
import { ReactComponent as EditIcon } from '../../../img/editIcon.svg';
import { ReactComponent as ShareIcon } from '../../../img/shareIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../img/downloadIcon.svg';
import closeBtn from '../../../img/close-btn.svg';
import { ReactComponent as PublicIcon } from '../../../img/publicIcon.svg';
import { ReactComponent as PrivateIcon } from '../../../img/privateIcon.svg';
import defaultUserPhoto from '../../../img/default-user-photo.svg';
import { ReactComponent as ReticleInfoEditIcon } from '../../../img/reticleInfoEditIcon.svg';
import { ReactComponent as SaveReticleInfoIcon } from '../../../img/saveReticleInfoIcon.svg';
import { ReactComponent as CancelReticleInfoIcon } from '../../../img/cancelReticleInfoIcon.svg';
import { ReactComponent as DiscardIcon } from '../../../img/moderatorReticleInfoDiscardIcon.svg';
import { ReactComponent as PublishIcon } from '../../../img/saveReticleInfoIcon.svg';
import { ReactComponent as ViewIcon } from '../../../img/moderatorReticleInfoViewIcon.svg';
import { ReactComponent as BanIcon } from '../../../img/banReticleInfoIcon.svg';


import { openReticleInfoStatus, savingPublicReticleInfoID, savingPublicReticleInfoStatus, openReticleInfoName, openReticleInfoDescription, openReticleInfoTags, getAllPublicFolders, publishError, openReticleInfoReason } from '../../../../redux/reticles/reticles-selectors';

import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, } from 'react-share';

import Modal from '../../../components/Modal/Modal';

import './reticleInfo.scss';

const ReticleInfo = ({ reticle,
    activeButton,
    activeTabId,
    onExportingTool,
    handleCloseCard,
    handlerActivateButton,
    deviceWidth
}) => {
    const dispatch = useDispatch();
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [activeSwitch, setActiveSwitch] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [openModal, setOpenModal] = useState(null);
    const [editableField, setEditableField] = useState('');

    const userEmail = useSelector(getUserEmail);
    const userRole = useSelector(getUserRole);
    const openedTabs = useSelector(getTabs);
    const openReticleStatus = useSelector(openReticleInfoStatus);
    const openReticleName = useSelector(openReticleInfoName);
    const openReticleDescription = useSelector(openReticleInfoDescription);
    const openReticleTags = useSelector(openReticleInfoTags);
    const openReticleReason = useSelector(openReticleInfoReason);


    const savingPublicReticleID = useSelector(savingPublicReticleInfoID);
    const savingPublicReticleStatus = useSelector(savingPublicReticleInfoStatus);

    const publicFolders = useSelector(getAllPublicFolders);
    const publishReticleError = useSelector(publishError);

    const baseUrl = process.env.REACT_APP_URL;
    let [shareUrl, setShareUrl] = useState(`${baseUrl}shared/?id=${reticle.id}`);
    const facebookBtn = useRef();
    const twitterBtn = useRef();
    const [isCopy, setIsCopy] = useState(false);

    const [activeSaveNotification, setActiveSaveNotification] = useState(false);
    const [isOpenDescription, setIsOpenDescription] = useState(false);

    const [firstRender, setFirstRender] = useState(false);
    const [openReticleStatusNotification, setOpenReticleStatusNotification] = useState(false);

    const [selectedType, setIsSelectedType] = useState(openReticleTags?.length ? openReticleTags[0] : 'FFP behavior');
    const [selectedRange, setIsSelectedRange] = useState(openReticleTags?.length ? openReticleTags[1] : 'Close range');
    const [selectedMeasurements, setIsSelectedMeasurements] = useState(openReticleTags?.length ? openReticleTags[2] : 'Ballistic');
    const [selectedRangefinder, setIsSelectedRangefinder] = useState(openReticleTags?.length ? openReticleTags[3] : 'With stadiametric rangefinder');

    const [isActiveType, setIsActiveType] = useState(false);
    const [isActiveRange, setIsActiveRange] = useState(false);
    const [isActiveMeasurements, setIsActiveMeasurements] = useState(false);
    const [isActiveRangefinder, setIsActiveRangefinder] = useState(false);

    const [activeFolder, setActiveFolder] = useState('');

    const [reason, setReason] = useState('');

    useEffect(() => {
        setFirstRender(true)
    }, []);

    const handleCloseOpenReticleStatusNotification = () => {
        setOpenReticleStatusNotification(false)
    };

    useEffect(() => {
        openReticleStatus === "PRIVATE" ? setActiveSwitch(true) : setActiveSwitch(false)
        if ((openReticleStatus === "PENDING" || openReticleStatus === "PRIVATE") && firstRender) {
            setOpenReticleStatusNotification(true)
        }
        if ((openReticleStatus === "PUBLIC") && firstRender) {
            setOpenReticleStatusNotification(false)
        }

    }, [openReticleStatus]);


    const handleClickSwitch = () => {
        const reticleInTabs = openedTabs.payload.tabs.tabs.find(el => el.savingId === reticle.id);

        let reticleStatus
        if (openReticleStatus === "NOT_APPROVED") {
            reticleStatus = "PUBLIC"
        } else if (openReticleStatus === "PRIVATE") {
            reticleStatus = "PUBLIC"
        } else if (openReticleStatus === "PUBLIC") {
            reticleStatus = "PRIVATE"
        } else if (openReticleStatus === "PENDING") {
            reticleStatus = "PRIVATE"
        };

        const payload = {
            id: reticle.id,
            status: reticleStatus,
            publicId: ''
        };

        if (reticleInTabs) {
            if (activeTabId !== reticleInTabs.id) {
                // console.log('not active tab')

                handleClickSwitchRole(payload);

                dispatch(addSavingID({ id: reticleInTabs.id, savId: reticle.id, status: reticleStatus, publicId: '' }));

                let historyData = JSON.stringify({
                    id: reticleInTabs.id,
                    status: reticleStatus,
                    publicId: '',
                    name: reticle.name,
                    savingId: reticle.id,
                    svg: reticle.reticleImg,
                    isActiveTab: false,
                    isLibOpen: true,
                });
                sessionStorage.setItem(reticleInTabs.id, historyData);


            } else if (activeTabId === reticleInTabs.id) {
                // console.log('active tab')

                handleClickSwitchRole(payload);

                // dispatch(changeStatusReticle(payload));
                dispatch(changeCurrentReticle({ status: reticleStatus, savingId: reticle.id, publicId: '' }));
                dispatch(addSavingID({ id: reticleInTabs.id, savId: reticle.id, status: reticleStatus, publicId: '' }));
                let historyData = JSON.stringify({
                    id: reticleInTabs.id,
                    status: reticleStatus,
                    publicId: '',
                    name: reticle.name,
                    savingId: reticle.id,
                    svg: reticle.reticleImg,
                    isActiveTab: true,
                    isLibOpen: true,
                });
                sessionStorage.setItem(reticleInTabs.id, historyData);

            }
        } else if (!reticleInTabs) {
            // console.log('NOTtab')

            handleClickSwitchRole(payload);
        }
        if (showModal) {
            handleToggleModal()
        }
    };

    const handleToggleModal = (value) => {
        if (value) {
            setOpenModal(value);
        }
        setShowModal(!showModal);

        if (openModal === 'publish') {
            setActiveFolder('');
        };
    };


    const handleClickSwitchRole = (payload) => {
        if (activeButton === '1') {
            dispatch(changeStatusReticle(payload));
        } else if (activeButton === '2' && userEmail === reticle.user.email) {
            dispatch(changeUserStatusReticleInPublic(payload));
        } else if (activeButton === '2' && (userRole === 'ADMIN' || userRole === 'MANAGER') && userEmail !== reticle.user.email && (openReticleStatus === "PUBLIC" || openReticleStatus === "PENDING")) {
            dispatch(changeAdminStatusReticleInPublic(payload));
            handleCloseCard();
        }
    };

    const handleDeleteReticle = () => {
        const reticleInTabs = openedTabs.payload.tabs.tabs.find(el => el.savingId === reticle.id);

        const payload = {
            headers: {},
            data: {
                reticleId: [reticle.id]
            }
        };

        if (reticleInTabs) {
            if (activeTabId !== reticleInTabs.id) {

                dispatch(deleteReticles(payload));
                dispatch(addSavingID({ id: reticleInTabs.id, savId: '', status: '', publicId: '' }));

                let jsonData = JSON.stringify({ id: reticleInTabs.id, savingId: '', svg: reticle.reticleImg, name: reticle.name, isActiveTab: false, status: '', publicId: '', });
                sessionStorage.setItem(reticleInTabs.id, jsonData);


            } else if (activeTabId === reticleInTabs.id) {

                dispatch(deleteReticles(payload))
                dispatch(changeCurrentReticle({ status: '', savingId: '', publicId: '' }));
                dispatch(addSavingID({ id: reticleInTabs.id, savId: '', status: '', publicId: '' }));

                let jsonData = JSON.stringify({ id: reticleInTabs.id, savingId: '', svg: reticle.reticleImg, name: reticle.name, isActiveTab: true, status: '', publicId: '', });
                sessionStorage.setItem(reticleInTabs.id, jsonData);

            }

        } else if (!reticleInTabs) {
            dispatch(deleteReticles(payload));
        }
        handleToggleModal();
        handleCloseCard();
    };

    const handleOpenReticle = () => {
        if (openedTabs.payload.tabs.tabs.length < 10) {
            let randomID = (Math.random() * 100000).toFixed(0);

            if (userRole === 'ADMIN' || userRole === 'MANAGER' || activeButton === '1' || (activeButton === '2' && userEmail === reticle.user.email)) {
                const payload = {
                    id: randomID,
                    name: openReticleName,
                    savingId: reticle.id,
                    svg: reticle.reticleImg,
                    isActiveTab: true,
                    status: reticle.status,
                    isLibOpen: true,
                    publicId: '',
                }

                if (isBtnDisabled) {
                    const openedTabID = openedTabs.payload.tabs.tabs.find(el => el.savingId === reticle.id);

                    dispatch(selectActiveTab({ id: openedTabID.id, action: 'SELECT' }));
                    dispatch(changeReticlesID({ id: openedTabID.id, action: 'SELECT' }));
                    dispatch(setCurrentZoomData(openedTabID.id));
                    dispatch(changeCurrentReticle({ status: reticle.status, savingId: reticle.id, publicId: '', reticleUserEmail: reticle.user.email }));

                } else {
                    dispatch(addTab(payload));
                    dispatch(addZoomData({ id: `tab-${payload.id}`, index: 100 }));
                    dispatch(changeReticlesID({ id: randomID, action: 'ADD' }));
                    dispatch(addColorThemeData({ id: `tab-${randomID}`, isNightMode: false, colorTheme: '#fff' }));
                    dispatch(changeCurrentReticle({ status: reticle.status, savingId: reticle.id, publicId: '', reticleUserEmail: reticle.user.email }));
                    dispatch(addGridData({ id: `tab-${randomID}`, gridType: 'moa', gridSize: 3 }));
                    dispatch(addDeviceData({ id: `tab-${payload.id}`, device: 'X-Sight 5', sensor: '320', lens: '3-15X', width: 1280, height: 960 }));
                }

                let historyData = JSON.stringify({ ...payload, id: `tab-${randomID}`, reticleUserEmail: reticle.user.email });
                sessionStorage.setItem(`tab-${randomID}`, historyData);

            } else if (activeButton === '2') {
                const payload = {
                    id: randomID,
                    name: `Copy of ${reticle.name}`,
                    savingId: '',
                    svg: reticle.reticleImg,
                    isActiveTab: true,
                    status: "PUBLIC",
                    isLibOpen: true,
                    publicId: reticle.id,
                }

                dispatch(addTab(payload))
                dispatch(addZoomData({ id: `tab-${payload.id}`, index: 100 }));
                dispatch(addColorThemeData({ id: `tab-${randomID}`, isNightMode: false, colorTheme: '#fff' }));
                dispatch(changeReticlesID({ id: randomID, action: 'ADD' }));
                dispatch(changeCurrentReticle({ status: "PUBLIC", savingId: '', publicId: reticle.id }));
                dispatch(addGridData({ id: `tab-${randomID}`, gridType: 'moa', gridSize: 3 }));
                dispatch(changeCalculationReticle(reticle.id));
                dispatch(addDeviceData({ id: `tab-${payload.id}`, device: 'X-Sight 5', sensor: '320', lens: '3-15X', width: 1280, height: 960 }));

                let historyData = JSON.stringify({ ...payload, id: `tab-${randomID}` });
                sessionStorage.setItem(`tab-${randomID}`, historyData);

            }

            let historyData = JSON.stringify(`tab-${randomID}`);
            sessionStorage.setItem(`activeTab`, historyData);
        }
        handlerActivateButton('1');
    };

    const handleShowReticle = () => {
        if (openedTabs.payload.tabs.tabs.length < 10) {
            let randomID = (Math.random() * 100000).toFixed(0);

            const payload = {
                id: randomID,
                name: `Copy of ${reticle.name}`,
                savingId: savingPublicReticleID,
                svg: reticle.reticleImg,
                isActiveTab: true,
                status: savingPublicReticleStatus,
                isLibOpen: true,
                publicId: '',
            }

            dispatch(addTab(payload));
            dispatch(addZoomData({ id: `tab-${payload.id}`, index: 100 }));
            dispatch(addColorThemeData({ id: `tab-${randomID}`, isNightMode: false, colorTheme: '#fff' }));
            dispatch(changeReticlesID({ id: randomID, action: 'ADD' }));
            dispatch(changeCurrentReticle({ status: savingPublicReticleStatus, savingId: savingPublicReticleID, publicId: '' }));
            dispatch(addGridData({ id: `tab-${randomID}`, gridType: 'moa', gridSize: 3 }));
            dispatch(addDeviceData({ id: `tab-${payload.id}`, device: 'X-Sight 5', sensor: '320', lens: '3-15X', width: 1280, height: 960 }));

            let data = JSON.stringify({ ...payload, id: `tab-${randomID}` });
            sessionStorage.setItem(`tab-${randomID}`, data);

            let historyData = JSON.stringify(`tab-${randomID}`);
            sessionStorage.setItem(`activeTab`, historyData);
        }
        handlerActivateButton('1');
    };

    const createMarkup = () => {
        return { __html: reticle.reticleImg };
    };

    const text = () => {
        if (reticle.user.firstName === 'null' && reticle.user.lastName === 'null') {
            return reticle.user.email.split('@', 2)[0]
        } else if (reticle.user.firstName !== null && reticle.user.lastName === null) {
            return reticle.user.firstName.slice(0, 50)
        } else if (reticle.user.firstName === null && reticle.user.lastName !== null) {
            return reticle.user.lastName.slice(0, 50)
        } else if (reticle.user.firstName !== null && reticle.user.lastName !== null) {
            return `${reticle.user.firstName} ${reticle.user.lastName}`.slice(0, 50)
        }
    };

    useEffect(() => {
        let openedDoc = openedTabs.payload.tabs.tabs.find(t => t.savingId === reticle.id);

        if (openedDoc) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [openedTabs.payload.tabs.tabs]);


    let handleCopyToClipboard = () => {
        navigator.clipboard.writeText(shareUrl);
        setIsCopy(true);

        setTimeout(() => {
            setIsCopy(false);
            navigator.clipboard.writeText('');
        }, 10000);
    };

    const handleSaveReticle = () => {
        var newSvg = reticle.reticleImg.replace(/data-reticleId="([^"]*)"/, '');
        var newSvg2 = newSvg.replace(/data-reticleid="([^"]*)"/, '');
        // console.log('newSvg2', newSvg2)

        const payload = {
            name: `Copy of ${reticle.name}`,
            reticleImg: newSvg2,
            shapesList: reticle.shapesList
        };

        dispatch(savePublicReticle(payload));
        setActiveSaveNotification(true);

        setTimeout(() => {
            setActiveSaveNotification(false)
        }, 5000);
    };

    const handleOpenDescription = () => {
        setIsOpenDescription(!isOpenDescription)
    };

    const handleCloseReticleNotification = () => {
        setActiveSaveNotification(false)
    };

    const handleChangeReticleInfo = (e) => {
        setEditableField(e.target.value)
    };

    const handleChangeReason = (e) => {
        setReason(e.target.value)
    };


    const handleUpdateReticleInfo = () => {
        const payload = {
            id: reticle.id,
            name: openModal === 'name' ? editableField : openReticleName,
            reticleImg: reticle.reticleImg,
            shapesList: reticle.shapesList,
            description: openModal === 'description' ? editableField : openReticleDescription,
            tags: openModal === 'tags' ? [selectedType, selectedRange, selectedMeasurements, selectedRangefinder] : openReticleTags,
        }
        if ((activeButton === '2' && (userRole === 'ADMIN' || userRole === 'MANAGER')) || (activeButton === '3' && (userRole === 'ADMIN' || userRole === 'MANAGER'))) {
            dispatch(resaveAdminReticleInfo(payload));

        } else dispatch(resaveReticleInfo(payload));

        const reticleInTabs = openedTabs.payload.tabs.tabs.find(el => el.savingId === reticle.id);

        if (reticleInTabs && openModal === 'name') {
            if (activeTabId !== reticleInTabs.id) {
                dispatch(setUpdateData({ id: reticleInTabs.id, name: editableField, savId: reticle.id, status: reticle.status, publicId: '' }));

                let jsonData = JSON.stringify({ id: reticleInTabs.id, savingId: reticle.id, svg: reticle.reticleImg, name: editableField, isActiveTab: false, status: reticle.status, publicId: '', });
                sessionStorage.setItem(reticleInTabs.id, jsonData);

            } else if (activeTabId === reticleInTabs.id) {
                dispatch(setUpdateData({ id: reticleInTabs.id, name: editableField, savId: reticle.id, status: reticle.status, publicId: '' }));

                let jsonData = JSON.stringify({ id: reticleInTabs.id, savingId: reticle.id, svg: reticle.reticleImg, name: editableField, isActiveTab: true, status: reticle.status, publicId: '', });
                sessionStorage.setItem(reticleInTabs.id, jsonData);

            }
        }
        handleToggleModal()
    };

    useEffect(() => {
        setEditableField(openModal === 'name' ? openReticleName : openReticleDescription);
    }, [openModal]);

    const handleFilterChangeTags = (title, textContent) => {
        if (title === 'Type') {
            setIsSelectedType(textContent)
        } else if (title === 'Range') {
            setIsSelectedRange(textContent)
        } else if (title === 'Measurements') {
            setIsSelectedMeasurements(textContent)
        } else if (title === 'Rangefinder') {
            setIsSelectedRangefinder(textContent)
        }
        // console.log([selectedType, selectedRange, selectedMeasurements, selectedRangefinder])
    };

    const handleActiveFolder = (value) => {
        setActiveFolder(value);
    };

    const handleAdminChangeStatus = (status) => {
        let payload
        reason ?
            payload = {
                id: reticle.id,
                status: status,
                reason
            } : payload = {
                id: reticle.id,
                status: status,
            }
        dispatch(changeAdminStatusReticle(payload));
        handleCloseCard();
    };

    const handlePublishReticle = () => {
        const statusPayload = {
            id: reticle.id,
            status: "PUBLIC",
        }
        const folderPayload = {
            reticleId: reticle.id,
            folderId: activeFolder
        }
        dispatch(publishReticle(statusPayload, folderPayload));
        handleCloseCard();
    };

    return (
        <>
            <div className='reticleCardInfo'>
                <div id={`${reticle.id}-${reticle.name}`} className='reticleCardInfo__body'>
                    <div className='reticleCardInfo__body-vsg' id={`${reticle.id}`} dangerouslySetInnerHTML={createMarkup()}>
                    </div>

                    <div className='reticleCardInfo__container'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {activeButton === '1' || (activeButton === '2' && userEmail === reticle.user.email) || (activeButton === '2' && (userRole === 'ADMIN' || userRole === 'MANAGER')) || (activeButton === '3' && (userRole === 'ADMIN' || userRole === 'MANAGER')) ? (
                                <button key={'Name'} onClick={() => handleToggleModal('name')} className='reticleCardInfo__button-desktop reticleCardInfo__button'><ReticleInfoEditIcon className='reticleCardInfo__button-icon' />Name</button>
                            ) : null}

                            <div className='reticleCardInfo__info' style={{ display: 'flex' }}>
                                {activeButton === '2' || activeButton === '3' ? (
                                    <img src={reticle.user.profPic ? reticle.user.profPic : defaultUserPhoto} alt='User avatar' className='reticleCardInfo__user-photo' />
                                ) : null}

                                <div>
                                    <h3 className='reticleCardInfo__title'>{openReticleName}</h3>
                                    {activeButton === '2' | activeButton === '3' ?
                                        (
                                            <>
                                                <p className='reticleCardInfo__text'>{text()}</p>
                                            </>
                                        )
                                        :
                                        (
                                            <p className='reticleCardInfo__text'>{reticle.createdAt.split('T')[0]}</p>
                                        )
                                    }

                                    {activeButton === '2' ?
                                        (
                                            <p className='reticleCardInfo__text'>{reticle.sumDownloads} downloads | {reticle.createdAt.split('T')[0]}</p>
                                        ) : null
                                    }
                                    {activeButton === '3' &&
                                        (
                                            <p className='reticleCard__description'>{reticle.createdAt.split('T')[0]}</p>
                                        )
                                    }

                                </div>
                            </div>

                        </div>


                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {activeButton === '1' || (activeButton === '2' && userEmail === reticle.user.email) || (activeButton === '2' && (userRole === 'ADMIN' || userRole === 'MANAGER')) || (activeButton === '3' && (userRole === 'ADMIN' || userRole === 'MANAGER')) ? (
                                <button key={'Tags'} className='reticleCardInfo__button-desktop  reticleCardInfo__button' onClick={() => handleToggleModal('tags')}><ReticleInfoEditIcon className='reticleCardInfo__button-icon' />Tags</button>
                            ) : null}
                            {openReticleTags?.length ? (
                                <ul style={{ padding: 0 }}>
                                    {openReticleTags.map(tag => (
                                        <button className='reticleCardInfo__tags' key={tag}>{tag}</button>
                                    ))}
                                </ul>
                            ) : null}
                        </div>



                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {activeButton === '1' || (activeButton === '2' && userEmail === reticle.user.email) || (activeButton === '2' && (userRole === 'ADMIN' || userRole === 'MANAGER')) || (activeButton === '3' && (userRole === 'ADMIN' || userRole === 'MANAGER')) ? (
                            <button key={'Description'} onClick={() => handleToggleModal('description')} className='reticleCardInfo__button-desktop  reticleCardInfo__button'><ReticleInfoEditIcon className='reticleCardInfo__button-icon' />Description</button>
                        ) : null}

                        {openReticleDescription ? (
                            <div className='reticleCardInfo__description' >

                                <div>
                                    <p style={{ margin: 0 }}>{isOpenDescription || openReticleDescription.length < 100 ? openReticleDescription : `${openReticleDescription.slice(0, 100)}...`}</p>
                                    {openReticleDescription.length > 100 ? (
                                        <button className='reticleCardInfo__description-button' onClick={handleOpenDescription}>{isOpenDescription ? 'Hide' : 'Show more'}</button>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div className='reticleCardInfo__actions-block'>

                        {deviceWidth >= 1020 ? (
                            <div className={openReticleStatus !== "NOT_APPROVED" ? 'reticleCardInfo__switch-block' : ''}>

                                {(openReticleStatus === "NOT_APPROVED" || activeButton === '3' || (userRole === 'USER' && userEmail !== reticle.user.email)) ? null : (
                                    <>
                                        <p>Public Library Access:</p>
                                        <PublicIcon />
                                        <label className="reticleCardInfo__switch">
                                            <input type="checkbox" id="togBtn" checked={activeSwitch} onChange={handleClickSwitch} />
                                            <span className="reticleCardInfo__slider reticleCardInfo__round"></span>
                                        </label>
                                        <PrivateIcon />
                                    </>
                                )}

                                {activeButton === '1' && openReticleStatus === "NOT_APPROVED" && (
                                    <>
                                        <div key={'Discard'} className='reticleCardInfo_banNotification'
                                            onClick={() => handleToggleModal('discard reason')}
                                        ><DiscardIcon className='reticleCardInfo__button-icon' />
                                            <div>
                                                <p style={{ margin: 0, color: '#FF5050' }}>This reticle was discarded by moderator.</p>
                                                <p style={{ margin: 0, color: '#AAAAAA' }}>{`${reticle.reason === null ? '' : reticle.reason} ...more`}</p>

                                            </div>
                                        </div>
                                    </>
                                )}

                                {activeButton === '1' && openReticleStatus === "BAN" && (
                                    <>
                                        <p style={{ margin: 0, color: '#FF5050' }}>BAN</p>
                                    </>
                                )}

                            </div>
                        ) : null}

                        <div className='reticleCardInfo__actionButton-block' style={{ display: 'flex', alignItems: 'center' }}>
                            {activeButton !== '3' && (
                                <>
                                    <button key={'Share'} className='reticleCardInfo__button' onClick={() => handleToggleModal('share')}><ShareIcon className='reticleCardInfo__button-icon' />Share</button>
                                    <button key={'Download'} className='reticleCardInfo__button' onClick={() => onExportingTool(reticle)}><DownloadIcon className='reticleCardInfo__button-icon' />Download</button>
                                    <button key={'Edit'} className='reticleCardInfo__button reticleCardInfo__button-active reticleCardInfo__button-desktop' onClick={handleOpenReticle}><EditIcon className='reticleCardInfo__button-icon' />Edit</button>

                                    {activeButton === '2' & userEmail !== reticle.user.email ?
                                        (
                                            <button key={'Save'} className='reticleCardInfo__button reticleCardInfo__button-active' onClick={handleSaveReticle}><SaveIcon className='reticleCardInfo__button-icon' />Save</button>
                                        ) : null
                                    }

                                    {activeButton === '1' ?
                                        (
                                            <button key={'Delete'} className='reticleCardInfo__button reticleCardInfo__button-delete' onClick={() => handleToggleModal('delete')}><DeleteIcon className='reticleCardInfo__button-icon' />Delete</button>
                                        ) : null
                                    }
                                </>
                            )}
                            {activeButton === '3' && (
                                <>
                                    <button key={'View'} className='reticleCardInfo__button' onClick={handleOpenReticle}><ViewIcon className='reticleCardInfo__button-icon' />View</button>
                                    <button key={'Publish'} className='reticleCardInfo__button reticleCardInfo__button-active' onClick={() => handleToggleModal('publish')}><PublishIcon className='reticleCardInfo__button-icon' />Publish</button>
                                    <button key={'Discard'} className='reticleCardInfo__button reticleCardInfo__button-delete' onClick={() => handleToggleModal('reason')}><DiscardIcon className='reticleCardInfo__button-icon' />Discard</button>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            {activeSaveNotification ? (

                <div className='reticleCardInfo__saveNotification-block'>
                    <p className='reticleCardInfo__saveNotification-text'>The reticle has been successfully saved to My Reticles.</p>
                    {deviceWidth < 1020 ? (
                        <button className='notification_close-btn' onClick={handleCloseReticleNotification}></button>
                    ) : (
                        <button className='reticleCardInfo__button reticleCardInfo__button-active' onClick={handleShowReticle}>Show</button>
                    )}
                </div>

            ) : null}

            {openReticleStatusNotification && (activeButton === '1' || activeButton === '2') && userEmail === reticle.user.email ? (

                <div className='reticleCardInfo__saveNotification-block' style={{ zIndex: 1 }}>
                    <p className='reticleCardInfo__saveNotification-text'>{openReticleStatus === "PENDING" ? 'Your reticle will be available in "Public reticles" after approval moderator' : 'Reticle visible only for you'}</p>
                    <button className='notification_close-btn' onClick={handleCloseOpenReticleStatusNotification}></button>
                </div>

            ) : null}


            {deviceWidth < 1020 ? (
                <>
                    {((activeButton === '1' && (openReticleStatus === "PRIVATE" || openReticleStatus === "PUBLIC" || openReticleStatus === "PENDING")) || (activeButton === '2' && userEmail === reticle.user.email)) && (

                        <div className='reticleCardInfo__switch-block-mobVersion'>
                            <p>Public Library Access:</p>
                            <PublicIcon />
                            <label className="reticleCardInfo__switch">
                                <input type="checkbox" id="togBtn" checked={activeSwitch} onChange={handleClickSwitch} />
                                <span className="reticleCardInfo__slider reticleCardInfo__round"></span>
                            </label>
                            <PrivateIcon />
                        </div>
                    )}

                    {activeButton === '1' && openReticleStatus === "NOT_APPROVED" && (
                        <div className='reticleCardInfo__notApproved-block-mobVersion'>
                            <div key={'Discard'} className='reticleCardInfo_banNotification'
                                onClick={() => handleToggleModal('discard reason')}
                            ><DiscardIcon className='reticleCardInfo__button-icon' />
                                <div>
                                    <p style={{ margin: 0, color: '#FF5050' }}>This reticle was discarded by moderator.</p>
                                    <p style={{ margin: 0, color: '#AAAAAA' }}>{`${reticle.reason === null ? '' : reticle.reason} ...more`}</p>

                                </div>
                            </div>
                        </div>
                    )}

                    {activeButton === '1' && openReticleStatus === "BAN" && (
                        <div className='reticleCardInfo_ban-block-mobVersion'>
                            <p style={{ margin: 0, color: '#FF5050' }}>BAN</p>
                        </div>
                    )}

                </>
            ) : null}


            {showModal && (
                <Modal toggleModal={handleToggleModal} width={openModal}>
                    <div className='reticleCardInfo__modal-section'>
                        {openModal === 'share' && (
                            <div className='modal-share-block'>
                                <div style={{ textAlign: 'start' }}>
                                    <h1 style={{ marginTop: 0, fontSize: 20 }}>Share</h1>
                                    <button onClick={handleToggleModal} className='modal__btn'>
                                        <img className='modal__svg' src={closeBtn} alt='close button svg' />
                                    </button>
                                </div>
                                <div className='reticleCardInfo__modalShareButton-block'>
                                    <FacebookShareButton
                                        ref={facebookBtn}
                                        url={shareUrl}
                                        quote={'My reticle'}
                                        hashtag={'#myreticle'}
                                    >
                                        <FacebookIcon size={60} round={true} />
                                        <p>Facebook</p>
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        ref={twitterBtn}
                                        url={shareUrl}
                                        title={'My reticle'}
                                        hashtag={'#myreticle'}
                                    >
                                        <TwitterIcon size={60} round={true} />
                                        <p>Twitter</p>
                                    </TwitterShareButton>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <input readOnly value={`${deviceWidth < 768 ? shareUrl.slice(0, 25) : shareUrl.slice(0, 42)}...`} className='reticleCardInfo__modalShareInput'></input>
                                    <button className='reticleCardInfo__button reticleCardInfo__button-active reticleCardInfo__modalShareInputButton' onClick={handleCopyToClipboard}>{isCopy ? 'Copied!' : 'Copy'}</button>
                                </div>

                            </div>
                        )}

                        {openModal === 'delete' && (
                            <div className='modal-delete-block'>
                                <div style={{ textAlign: 'start' }}>
                                    <h1 style={{ fontSize: 20 }}>Confirm Delete</h1>
                                    <p>Are you sure you want to delete this item? This action cannot be undone.</p>
                                </div>
                                <div className='modal-delete-actionButton'>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-delete' onClick={handleDeleteReticle}><DeleteIcon className='reticleCardInfo__button-icon' />Permanently delete the item</button>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-active' onClick={handleToggleModal}>Keep the item</button>
                                </div>
                            </div>
                        )}

                        {(openModal === 'name' || openModal === 'description') && (
                            <div className='modal-delete-block'>
                                <div style={{ textAlign: 'start' }}>
                                    <h1 style={{ fontSize: 20 }}>{openModal === 'name' ? 'Name' : 'Description'}</h1>
                                    <textarea id='surname' className='userAccountInput'
                                        type="text"
                                        value={editableField === null ? '' : editableField}
                                        onChange={handleChangeReticleInfo}
                                    />
                                </div>
                                <div className='modal-delete-actionButton' style={{ justifyContent: 'end' }}>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-active' onClick={handleUpdateReticleInfo}><SaveReticleInfoIcon className='reticleCardInfo__button-icon' />Save</button>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-delete' style={{ display: 'flex', alignItems: 'center' }} onClick={handleToggleModal}><CancelReticleInfoIcon className='reticleCardInfo__button-icon' />Cancel</button>
                                </div>
                            </div>
                        )}

                        {openModal === 'discard reason' && (
                            <div className='modal-delete-block'>
                                <div style={{ textAlign: 'start' }}>
                                    <h1 style={{ fontSize: 20 }}>Discard reason</h1>
                                    <p>{reticle.reason === null ? '' : reticle.reason}</p>
                                </div>
                                <div className='modal-delete-actionButton'>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-delete'
                                        onClick={handleClickSwitch}
                                    ><BanIcon className='reticleCardInfo__button-icon' />Resubmit reticle for moderator review</button>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-active' onClick={handleToggleModal}>Cancel</button>
                                </div>
                            </div>
                        )}

                        {openModal === 'reason' && (
                            <div className='modal-delete-block'>
                                <div style={{ textAlign: 'start' }}>
                                    <h1 style={{ fontSize: 20 }}>Reason</h1>
                                    <textarea id='surname' className='userAccountInput'
                                        type="text"
                                        value={reason}
                                        onChange={handleChangeReason}
                                    />
                                </div>
                                <div className='modal-delete-actionButton' style={{ justifyContent: 'end' }}>
                                    <button key={'Discard'} className='reticleCardInfo__button reticleCardInfo__button-delete' onClick={() => handleAdminChangeStatus('NOT_APPROVED')}><DiscardIcon className='reticleCardInfo__button-icon' />Discard</button>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-delete' style={{ display: 'flex', alignItems: 'center' }} onClick={handleToggleModal}><CancelReticleInfoIcon className='reticleCardInfo__button-icon' />Cancel</button>
                                </div>
                            </div>
                        )}

                        {(openModal === 'tags') && (
                            <div>

                                <h1 style={{ fontSize: 20 }}>Tags</h1>

                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Type'}>
                                        <p style={{ margin: 0, color: '#AAAAAA' }}>Type</p>

                                        <div className="dropdown">

                                            <div onClick={(e) => { setIsActiveType(!isActiveType); }} style={{ backgroundColor: isActiveType ? '#131318' : 'transparent' }} className="dropdown-btn">
                                                {selectedType}

                                                <span className={isActiveType ? "vectorTop" : "vectorBottom"} />
                                            </div>

                                            <div className="dropdown-content" style={{ display: isActiveType ? "block" : "none" }}>
                                                {selectedType !== 'FFP behavior' && (
                                                    <div key={'FFP behavior'} onClick={() => { handleFilterChangeTags('Type', 'FFP behavior'); setIsActiveType(!isActiveType); }} className="item">
                                                        FFP behavior
                                                    </div>
                                                )}
                                                {selectedType !== 'SFP behavior' && (
                                                    <div key={'SFP behavior'} onClick={() => { handleFilterChangeTags('Type', 'SFP behavior'); setIsActiveType(!isActiveType); }} className="item">
                                                        SFP behavior
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Range'}>
                                        <p style={{ margin: 0, color: '#AAAAAA' }}>Range</p>

                                        <div className="dropdown">

                                            <div onClick={(e) => { setIsActiveRange(!isActiveRange); }} style={{ backgroundColor: isActiveRange ? '#131318' : 'transparent' }} className="dropdown-btn">
                                                {selectedRange}

                                                <span className={isActiveRange ? "vectorTop" : "vectorBottom"} />
                                            </div>

                                            <div className="dropdown-content" style={{ display: isActiveRange ? "block" : "none" }}>
                                                {selectedRange !== 'Close range' && (
                                                    <div key={'Close range'} onClick={() => { handleFilterChangeTags('Range', 'Close range'); setIsActiveRange(!isActiveRange); }} className="item">
                                                        Close range
                                                    </div>
                                                )}
                                                {selectedRange !== 'Mid-long range' && (
                                                    <div key={'Mid-long range'} onClick={() => { handleFilterChangeTags('Range', 'Mid-long range'); setIsActiveRange(!isActiveRange); }} className="item">
                                                        Mid-long range
                                                    </div>
                                                )}
                                                {selectedRange !== 'Standard' && (
                                                    <div key={'Standard'} onClick={() => { handleFilterChangeTags('Range', 'Standard'); setIsActiveRange(!isActiveRange); }} className="item">
                                                        Standard
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Measurements'}>
                                        <p style={{ margin: 0, color: '#AAAAAA' }}>Measurements</p>

                                        <div className="dropdown">

                                            <div onClick={(e) => { setIsActiveMeasurements(!isActiveMeasurements); }} style={{ backgroundColor: isActiveMeasurements ? '#131318' : 'transparent' }} className="dropdown-btn">
                                                {selectedMeasurements}

                                                <span className={isActiveMeasurements ? "vectorTop" : "vectorBottom"} />
                                            </div>

                                            <div className="dropdown-content" style={{ display: isActiveMeasurements ? "block" : "none" }}>
                                                {selectedMeasurements !== 'Ballistic' && (
                                                    <div key={'Ballistic'} onClick={() => { handleFilterChangeTags('Measurements', 'Ballistic'); setIsActiveMeasurements(!isActiveMeasurements); }} className="item">
                                                        Ballistic
                                                    </div>
                                                )}
                                                {selectedMeasurements !== 'Non-ballistic' && (
                                                    <div key={'Non-ballistic'} onClick={() => { handleFilterChangeTags('Measurements', 'Non-ballistic'); setIsActiveMeasurements(!isActiveMeasurements); }} className="item">
                                                        Non-ballistic
                                                    </div>
                                                )}
                                                {selectedMeasurements !== 'With angular measurements' && (
                                                    <div key={'With angular measurements'} onClick={() => { handleFilterChangeTags('Measurements', 'With angular measurements'); setIsActiveMeasurements(!isActiveMeasurements); }} className="item">
                                                        With angular measurements
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Rangefinder'}>
                                        <p style={{ margin: 0, color: '#AAAAAA' }}>Rangefinder</p>

                                        <div className="dropdown">

                                            <div onClick={(e) => { setIsActiveRangefinder(!isActiveRangefinder); }} style={{ backgroundColor: isActiveRangefinder ? '#131318' : 'transparent' }} className="dropdown-btn">
                                                {selectedRangefinder}

                                                <span className={isActiveRangefinder ? "vectorTop" : "vectorBottom"} />
                                            </div>

                                            <div className="dropdown-content" style={{ display: isActiveRangefinder ? "block" : "none" }}>
                                                {selectedRangefinder !== 'With stadiametric rangefinder' && (
                                                    <div key={'With stadiametric rangefinder'} onClick={() => { handleFilterChangeTags('Rangefinder', 'With stadiametric rangefinder'); setIsActiveRangefinder(!isActiveRangefinder); }} className="item">
                                                        With stadiametric rangefinder
                                                    </div>
                                                )}
                                                {selectedRangefinder !== 'Without rangefinding capability' && (
                                                    <div key={'Without rangefinding capability'} onClick={() => { handleFilterChangeTags('Rangefinder', 'Without rangefinding capability'); setIsActiveRangefinder(!isActiveRangefinder); }} className="item">
                                                        Without rangefinding capability
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-active' onClick={handleUpdateReticleInfo}><SaveReticleInfoIcon className='reticleCardInfo__button-icon' />Save</button>
                                    <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-delete' style={{ display: 'flex', alignItems: 'center' }} onClick={handleToggleModal}><CancelReticleInfoIcon className='reticleCardInfo__button-icon' />Cancel</button>
                                </div>

                            </div>
                        )}

                        {(openModal === 'publish') && (
                            <div className='modal-delete-block'>
                                <div style={{ textAlign: 'start' }}>
                                    <section className='reticleCardInfo_folder-block'>

                                        <h1 style={{ fontSize: 20 }}>Select folder</h1>
                                        <button key={'1'} className={`libraries_adminFolderButton libraries_navButton-primary button_publicLibIcon`}>Public reticles</button>
                                        {publicFolders.length ? (
                                            publicFolders.map(folder => (
                                                <button key={folder.id} className={`libraries_navButton libraries_navButton-secondary secondaryButton_publicLibIcon  ${activeFolder === folder.id ? 'libraries_navButton-active secondaryButton_openFolderIcon' : ''}`} onClick={() => handleActiveFolder(folder.id)}>{folder.name}</button>
                                            ))
                                        ) : null}
                                    </section>
                                </div>
                                <div className='modal-delete-actionButton' style={{ justifyContent: 'end' }}>
                                    <button disabled={activeFolder !== '' ? false : true} className={`${activeFolder !== '' ? 'reticleCardInfo__button-active reticleCardInfo__button' : 'reticleCardInfo__button-disabled'}`} onClick={() => handlePublishReticle()}><SaveReticleInfoIcon className='reticleCardInfo__button-icon' />Publish</button>
                                    <button className='reticleCardInfo__button reticleCardInfo__button-delete' style={{ display: 'flex', alignItems: 'center' }} onClick={handleToggleModal}><CancelReticleInfoIcon className='reticleCardInfo__button-icon' />Cancel</button>
                                </div>
                            </div>
                        )}

                    </div>
                </Modal>
            )}
        </>
    )
}

export default ReticleInfo;

